.login-container {
  display: flex;
  height: 100vh;
}
.login-container_left {
  background-image: url('./components/authenticate//imgs/login-left-img.png');
  background-position: center;
  background-size: cover;
  flex-basis: 100%;
}
.login-container_right {
  flex-basis: 100%;
  background-color: #fff;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.login-paper {
  padding: 5rem;
}

@media screen and (max-width: 700px) {
  .login-container_left {
    display: none;
  }

  .login-container_right {
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .login-paper {
    padding: 1rem;
  }
}
