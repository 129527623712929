.testimonial-container {
  background-image: url('./imgs/testimonial-pattern.png');
  min-height: 20rem;
  background-position: center;
  background-size: cover;
  background-color: #fff;
  padding: 2rem 6rem;
}

.testimonial-container h4 {
  font-size: 2.4rem;
  color: #525252fa;
  margin: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1px;
}

.testimonial-cards {
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
  justify-content: center;
}

.choose-us-container {
  background-color: #fff;
  padding: 2rem 6rem;
  min-height: 20rem;
}

.choose-us-cards {
  display: flex;
  gap: 2rem;
  padding: 1rem 0;
  justify-content: space-between;
  width: 100%;
}

.choose-us-cards_item {
  flex-grow: 1;
  flex-basis: 0;
}

.choose-us-container h4 {
  font-size: 2.4rem;
  color: black;
  margin: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1px;
}

.numbers-container {
  background-image: url('./imgs/numbers-bg.png');
  min-height: 20rem;
  background-position: center;
  background-size: cover;
  padding: 1rem 6rem;
  background-color: #005ac1;
}

.numbers-cards {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 1rem;
}

.works-container {
  min-height: 20rem;
  margin: 0;
  padding: 0rem 6rem;
  background-color: #fff;
}

.works-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 1rem;
}

.sources-container {
  min-height: 8rem;
  margin: 0;
  background-color: #fff;
}

.live-projects-container {
  background-image: url('./imgs/live-pattern.png');
  min-height: 20rem;
  margin: 0;
  background-position: top;
  background-size: cover;
  padding: 0rem 6rem;
  background-color: #fff;
}
.about-us-container {
  background-image: url('./imgs/numbers-bg.png');
  min-height: 20rem;
  padding: 6rem 1rem;
  font-size: 2rem;
  background-color: #005ac1;
  color: #fff;
  margin-bottom: 120px;
}

.about-us-container p {
  font-size: initial;
}

.who-we-are {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  text-align: center;
  gap: 2rem;
  text-transform: uppercase;
  margin-bottom: 120px;
}
.who-we-are h2 {
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 700;
}
.who-we-are p {
  font-weight: 400;
  text-transform: initial;
}

.about-us-details {
  padding-left: 1rem;
  margin-bottom: 120px;
  max-width: 85%;
}
.about-us-details > h3 {
  color: #000;
  margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

/* values */
.values-container {
  margin: 8rem 0;
}
.values-container h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 4rem;
}

.values_spheres {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem;
}

.values {
  display: flex;
  flex-direction: row;
  max-width: 1140px;
  padding: 0 1rem;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

/* cultures */
.culture {
  display: flex;
  max-width: 1140px;
  padding: 0 1rem;
  margin: 1rem auto;
  gap: 2rem;
}

.culture p {
  text-align: justify;
}
.image-container {
  text-align: center;
}
figure h3 {
  color: #000;
}
figure img {
  filter: invert(20%) sepia(58%) saturate(4403%) hue-rotate(202deg)
    brightness(92%) contrast(101%);
  max-width: 100%;
  width: 70px;
  height: auto;
}
.join-us {
  margin-left: 1rem;
  max-width: 85%;
}
.join-us h3 {
  text-transform: uppercase;
}

.solution-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.action-container {
  background-image: url('./imgs/live-pattern.png');
  min-height: 20rem;
  margin-top: 3rem;
  background-position: top;
  background-size: cover;
  background-color: #fff;
  min-height: 20rem;
  border-radius: 10px;
  padding: 0 1rem;
  text-align: center;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #000;
  font-size: 2rem;
  font-weight: 600;
}
.screen {
  width: 85vw;
  overflow: hidden;
}
.action-message {
  animation: move 10s linear infinite;
}
@keyframes move {
  from {
    transform: translateX(40%);
  }

  to {
    transform: translateX(-100%);
  }
}
.action-btn {
  margin-top: 1rem;
  padding: 1rem 2rem;
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  background-color: #005ac1;
  border-radius: 5px;
}

.action-btn:hover {
  opacity: 0.8;
}
@media screen and (max-width: 700px) {
  .choose-us-cards {
    flex-wrap: wrap;
  }

  .numbers-cards {
    display: block;
  }

  .works-cards {
    flex-direction: column;
    gap: 2rem;
  }
  .testimonial-cards {
    flex-direction: column;
  }

  .choose-us-container {
    padding: 2rem 3rem;
  }

  .choose-us-cards {
    display: block;
  }
  .choose-us-cards_item {
    margin-top: 1rem;
  }

  .testimonial-container {
    padding: 0rem 3rem;
  }

  .numbers-container {
    padding: 2rem 0rem;
  }

  .works-container {
    padding: 0rem 2rem;
  }

  .live-projects-container {
    padding: 0rem 0rem;
  }

  .values {
    flex-direction: column;
  }

  .values_spheres img {
    max-width: 100%;
    width: 100%;
  }

  .culture {
    flex-wrap: wrap;
    max-width: 300px;
  }
}
