@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.content {
  width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background: #fff;
  overflow-x: hidden;
}

.app {
  display: flex;
  width: 100%;
}

input {
  -webkit-text-size-adjust: none; /* Safari */
  -moz-text-size-adjust: none; /* Firefox */
  -ms-text-size-adjust: none; /* IE */
  text-size-adjust: none;
}

.btn {
  background-color: #005ac1 !important;
  color: #fff !important;
  padding: 0 !important;
  text-transform: capitalize !important;
  padding: 0.1rem 0.5rem !important;
  font-size: 12px !important;
}

.btn-err {
  background-color: #ea6363 !important;
  color: #fff !important;
  padding: 0 !important;
  text-transform: capitalize !important;
  padding: 0.1rem 0.5rem !important;
  font-size: 12px !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 700px) {
  html {
    font-size: 80%;
  }
}
